import React, { useState, useEffect } from 'react';
import MemberManagement from '../../../helperFunctions/DashboardScenesHelpers/MemberManagementComponents/MemberManagement';
import ExpiringMemberSnippet from '../../../helperFunctions/DashboardScenesHelpers/MemberManagementComponents/ExpiringMemberSnippet';
import { getMemberData } from '../../../helperFunctions/DashboardScenesHelpers/MemberManagementComponents/FetchMembersData';
import { MembersAndPTGraph } from '../../../helperFunctions/DashboardScenesHelpers/MemberManagementComponents/MembersAndPTGraph';
import { AgeGraph } from '../../../helperFunctions/DashboardScenesHelpers/MemberManagementComponents/AgeGraph';
import SidePanel from '../../../components/SidePanel';
import { ClientButtons } from '../../../helperFunctions/DashboardScenesHelpers/MemberManagementComponents/ClientButtons';
import AddRevenue from '../../../helperFunctions/DashboardScenesHelpers/FinanceComponents/AddRevenue';
import OverAllAttendance from '../../../helperFunctions/DashboardScenesHelpers/MainDisplayPageHelpers/OverallAttendance';
import PTSessionModal from '../../../helperFunctions/DashboardScenesHelpers/MemberManagementComponents/PTSession';
import LoadingSpinner from '../../../components/LoadingSpinner';

/**
 * 
 * @param {Object} gymData - Contains information about the gym
 * @param {String} activeTab - ?
 * @returns {JSX.Element} T
 * @returns 
 */
export const ClientsManagement = ({ gymData, activeTab }) => {

    const [memberData,      setMemberData]      = useState([]);
    const [showAllMembers,  setShowAllMembers]  = useState(false);
    const [isPanelExpanded, setIsPanelExpanded] = useState(false);
    const [selectedMember,  setSelectedMember]  = useState(null);
    const [showAddMember,   setShowAddMember]   = useState(false);
    const [showPtModal,     setShowPtModal]     = useState(false);
    const [basicGymData, ]                      = useState({
        city    : gymData.city      || "",  // Ensure gymData has the required fields
        gymName : gymData.gymName   || ""
    });
    const [loading,         setIsLoading]       = useState(true);

    const togglePanel = () => {
        setIsPanelExpanded(!isPanelExpanded);
    };

    useEffect(() => {
        const fetchData = async () => {
            try {
                const data = await getMemberData(basicGymData);
                setMemberData(data);
                setIsLoading(false);
            } catch (error) {
                console.error('Error fetching member data:', error);
            }
        };
        fetchData();
    }, [basicGymData]);

    const handleShowAllMembers = () => {
        setShowAllMembers(true);
    };

    const handleBackToExpiringMembers = () => {
        setShowAllMembers(false);
    };

    /**
     * Toggles the visibility of the Add Member component.
     */
    const toggleAddMember = () => {
        setShowAddMember((prev) => !prev);
    };

    return (
        <>
            {!loading ? (
                <div className="flex flex-row w-full pl-8 pr-4">
                    <div className="w-full">
                        <p className="text-left text-3xl font-bold text-secondary">Clients</p>
                        {showAllMembers && (
                            <button onClick={handleBackToExpiringMembers} className="relative top w-fit px-4 py-2 bg-secondary text-black rounded-xl">
                                &larr; Back
                            </button>
                        )}
                        <div className="flex flex-col lg:flex-row justify-between py-4">
                            {showAddMember ? (
                                <div className="justify-center w-full">
                                    <AddRevenue onBackClick={toggleAddMember} gymData={gymData} lastPage='Clients' />
                                </div>
                            ) : !showAllMembers ? (
                                <>
                                    <div className="flex flex-col w-full space-y-6">
                                        <div className='flex flex-col lg:flex-row justify-between gap-8'>
                                            <OverAllAttendance gymData={gymData} activeTab={activeTab}/>
                                            {/* <GenderGraph gymData={basicGymData} /> */}
                                        </div>
                                        <div className='flex flex-col lg:flex-row justify-between gap-8'>
                                            <MembersAndPTGraph gymData={gymData}/>
                                            {/* Age Graph */}
                                            <AgeGraph gymData={basicGymData} legendLayout="vertical"/>
                                            <ClientButtons toggleAddMember={toggleAddMember} setShowPtModal={setShowPtModal}/>
                                        </div>
                                        <div className='flex flex-col lg:flex-row'>
                                            <ExpiringMemberSnippet memberData={memberData} onShowAllMembers={handleShowAllMembers} />
                                        </div>
                                    </div>
                                    <PTSessionModal isOpen={showPtModal} onClose={() => setShowPtModal(false)} gymData={gymData}/>
                                </>
                            ) : (
                                <MemberManagement memberData={memberData} gymData={gymData} basicGymData={basicGymData} selectedMember={selectedMember} setSelectedMember={setSelectedMember} />
                            )}
                        </div>
                    </div>
                    <SidePanel activeTab={activeTab} isExpanded={isPanelExpanded} togglePanel={togglePanel} />
                </div>
            ) : (
                <LoadingSpinner />
            )}
        </> 
    );
};