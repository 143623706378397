import React, { useState, useEffect } from 'react';
import { fetchRevenueReceipts } from './FetchFinanceData';
import ReceiptDetailsModal from './ReceiptDetailsModal'; // Import the modal component
import { truncateText } from '../../basicHelper';

// Defining how many receipts I need per page
const receiptsPerPage = 12;

/**
 * PaymentReceipts component to display payment receipts with tabs for filtering by type
 * and a search function to filter by user name.
 *
 * @param {Function} onBackClick - Function to handle the back button click event.
 * @param {Object} gymData - Contains Gym Information
 * @returns {JSX.Element}
 */
const PaymentReceipts = ({ onBackClick, gymData }) => {
    const [payments       , setPayments       ] = useState([]);
    const [searchTerm     , setSearchTerm     ] = useState('');
    const [currentPage    , setCurrentPage    ] = useState(1);
    const [activeTab      , setActiveTab      ] = useState('All');
    const [selectedReceipt, setSelectedReceipt] = useState(null); // Track the selected receipt
    const [isMobile       , setIsMobile       ] = useState(window.innerWidth <= 426)
    
    // Fetches payments data when the component is mounted or gymData changes.
    useEffect(() => {
        const fetchData = async () => {
            const data = await fetchRevenueReceipts(gymData);
            setPayments(data);
        };
        fetchData();
    }, [gymData]);

    const sortedReceipts = payments.sort((a, b) => new Date(b.timestamp) - new Date(a.timestamp));
    const filteredReceipts = getFilteredReceipts(sortedReceipts, activeTab, searchTerm);

    const indexOfLastReceipt = currentPage * receiptsPerPage;
    const indexOfFirstReceipt = indexOfLastReceipt - receiptsPerPage;
    const currentReceipts = filteredReceipts.slice(indexOfFirstReceipt, indexOfLastReceipt);
    const totalPages = Math.ceil(filteredReceipts.length / receiptsPerPage);

    const handlePageChange = (page) => {
        if (page >= 1 && page <= totalPages) {
            setCurrentPage(page);
        }
    };

    // Function to handle receipt click and open modal
    const handleReceiptClick = (receipt) => {
        setSelectedReceipt(receipt);
    };

    // Function to close the modal
    const closeModal = () => {
        setSelectedReceipt(null);
    };

    // Helper function for dynamic pagination with ellipsis
    const getPaginationButtons = (currentPage, totalPages) => {
        const delta = isMobile ? 1 : 2;
        const range = [];
        const left = Math.max(2, currentPage - delta);
        const right = Math.min(totalPages - 1, currentPage + delta);

        range.push(1);
        if (left > 2) range.push('...');
        for (let i = left; i <= right; i++) range.push(i);
        if (right < totalPages - 1) range.push('...');
        if (totalPages > 1) range.push(totalPages);

        return range;
    };

    return (
        // making it responsive
        <div className="pr-2 py-3 lg:px-8 lg:py-8">
            <div className="flex justify-between items-start lg:items-center mb-4 gap-2">
                <button className="bg-secondary text-white py-2 px-1 lg:px-4 rounded hover:bg-primary text-sm min-w-16" onClick={onBackClick}>
                    &#8592; Back
                </button>
                <div className="flex flex-col md:flex-row items-center gap-2 space-x-4 max-w-[70%] md:max-w-full">
                    <div className="flex space-x-2 py-1 px-1 lg:px-4 rounded text-sm lg:text-base max-w-[100%] md:max-w-full overflow-x-scroll lg:overflow-x-auto ">
                        {['All', 'Gym Membership', 'Classes', 'Other'].map((tab) => (
                            <button
                                key={tab}
                                className={`tab-button py-1 text-sm lg:text-base px-2 lg:px-4 lg:py-2 rounded min-w-36 md:min-w-fit ${activeTab === tab ? 'bg-secondary text-white' : 'bg-white text-black'} transition-all duration-500`}
                                onClick={() => {
                                    setActiveTab(tab);
                                    setCurrentPage(1); // Reset to first page when tab changes
                                }}
                            >
                                {tab}
                            </button>
                        ))}
                    </div>
                    <input
                        type="text"
                        className="border rounded py-1 px-2 lg:px-4 lg:py-2 text-sm lg:text-base w-full md:w-auto -ml-1 md:ml-0"
                        placeholder="Search by name"
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                    />
                </div>
            </div>

            {currentReceipts.length > 0 ? (
                <>
                    {renderAllReceipts(currentReceipts, handleReceiptClick)}

                    {/* Pagination buttons */}
                    <div className="flex justify-between md:justify-center space-x-2 mt-4">
                        <button
                            className={`text-sm md:text-base py-1 px-2 lg:py-2 lg:px-4 rounded ${currentPage === 1 ? 'bg-gray-300 text-gray-500' : 'bg-primary text-white'} transition-all duration-500`}
                            onClick={() => handlePageChange(currentPage - 1)}
                            disabled={currentPage === 1}
                        >
                            {isMobile ? 'Prev ' : 'Previous'}
                        </button>

                        <div className='flex gap-2 md:gap-4'>
                        {getPaginationButtons(currentPage, totalPages).map((page, index) => (
                            <button
                                key={index}
                                className={`text-sm md:text-base py-1 px-2 lg:py-2 lg:px-4 rounded ${currentPage === page ? 'bg-secondary text-white scale-110' : 'bg-white text-black border'} transition-all duration-500`}
                                onClick={() => typeof page === 'number' && handlePageChange(page)}
                                disabled={page === '...'}
                            >
                                {page}
                            </button>
                        ))}
                        </div>

                        <button
                            className={`text-sm md:text-base py-1 px-2 lg:py-2 lg:px-4 rounded ${currentPage === totalPages ? 'bg-gray-300 text-gray-500' : 'bg-primary text-white'} transition-all duration-500`}
                            onClick={() => handlePageChange(currentPage + 1)}
                            disabled={currentPage === totalPages}
                        >
                            Next
                        </button>
                    </div>
                </>
            ) : (
                <p className="text-center text-lg">No receipts found.</p>
            )}

            {selectedReceipt && (
                <ReceiptDetailsModal
                    receipt={selectedReceipt}
                    onClose={closeModal}
                    gymData={gymData}
                />
            )}
        </div>
    );
};

export default PaymentReceipts;

/**
 * Filters receipts based on the activeTab, and
 * Handles Search Bar
 * @param {Object} receipts     - All the receipts
 * @param {String} activeTab    - The selected receipt type format
 * @param {String} searchTerm   - Search Term
 * @returns {Object}            - Filtered Receipts
 */
const getFilteredReceipts = (receipts, activeTab, searchTerm) => {
    return receipts.filter((receipt) => {
        const matchesTab = activeTab === 'All' ||
            (activeTab === 'Gym Membership' && receipt.orderType === 'Gym Membership') ||
            (activeTab === 'Classes' && receipt.orderType === 'Classes') ||
            (activeTab === 'Other' && receipt.orderType !== 'Gym Membership' && receipt.orderType !== 'Classes');
        
        const matchesSearch = receipt.userName.toLowerCase().includes(searchTerm.toLowerCase());
        
        return matchesTab && matchesSearch;
    });
};

/**
 * Renders the list of all receipts in a grid format. Each receipt is displayed
 * as a clickable card, which triggers a modal with detailed information upon clicking.
 * The `userName` is truncated to avoid overflow, and a line is placed under the username
 * and above the amount to make the card look more like a receipt.
 * 
 * @param {Array} receipts - Array of receipt objects to be displayed.
 * @param {Function} handleReceiptClick - Function to handle the event when a receipt is clicked.
 * @returns {JSX.Element} - The rendered grid of receipt cards.
 */
const renderAllReceipts = (receipts, handleReceiptClick) => {
    return (
        <div className="-ml-3 -mr-2 lg:mx-0 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4">
            {receipts.map((receipt, index) => (
                <div
                    key={index}
                    className="p-4 bg-white rounded-lg shadow-md relative cursor-pointer"
                    onClick={() => handleReceiptClick(receipt)}
                >
                    <div className="flex justify-between items-center mb-2">
                        <span className="min-w-20 text-base lg:text-lg font-medium">
                            <strong>{truncateText(receipt.displayName || receipt.userName, 10)}</strong>
                        </span>
                        <span className="text-xs lg:text-sm text-logoGray">
                            {new Date(receipt.timestamp).toLocaleString('en-GB')}
                        </span>
                    </div>
                    <hr className="border-secondary my-2" />
                    <div className="flex flex-col gap-1">
                        {receipt.orderType === 'Gym Membership' && (
                            <>
                                <span className="text-sm text-left"><strong>Months:</strong> {receipt.months}</span>
                                <span className="text-sm text-left"><strong>Order Type:</strong> {receipt.orderType}</span>
                                <span className="text-sm text-left"><strong>Payment ID:</strong> {receipt.paymentID}</span>
                            </>
                        )}
                        {receipt.orderType === 'Classes' && (
                            <>
                                <span className="text-sm text-left"><strong>Class Name:</strong> {receipt.className}</span>
                                <span className="text-sm text-left"><strong>Date and Time:</strong> {receipt.classDate} at {receipt.classTime}</span>
                                <span className="text-sm text-left"><strong>Payment ID:</strong> {receipt.paymentID}</span>
                            </>
                        )}
                        {receipt.orderType !== 'Gym Membership' && receipt.orderType !== 'Classes' && (
                            <>
                                <span className="text-sm text-left"><strong>Description:</strong> {receipt.userName}</span>
                                <span className="text-sm text-left"><strong>Order Type:</strong> Other</span>
                                <span className="text-sm text-left"><strong>Payment ID:</strong> {receipt.paymentID}</span>
                            </>
                        )}
                        <hr className="border-secondary my-2" />
                        <span className="text-right text-lg font-bold">₹ {receipt.amount / 100}</span>
                    </div>
                </div>
            ))}
        </div>
    );
};
