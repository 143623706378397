import { collection, doc, getDoc, getDocs, query, where } from "@firebase/firestore";
import { db } from "../../../config/firebase";
import { cityList, financeList, gymList, revenueList, userList } from "../../../components/Databases";
import { getCurrentYear, getDate, parseDate } from "../../basicHelper";

/**
 * Returns the gender data array, excluding the member with doc ID 'testUser'.
 * @param {Object} gymData - Contains the gym details
 * @returns {Array} Array of gender distribution (Male, Female, Others)
 */
export const getGenderData = async (gymData) => {
    let male = 0;
    let female = 0;
    let others = 0;

    const genderRef = collection(db, cityList, gymData.city, gymList, gymData.gymName, userList);
    const snapshot = await getDocs(genderRef);
    
    snapshot.forEach(doc => {
        // Skip the member with doc ID 'testUser'
        if (doc.id === 'testUser') {
            return;
        }

        const { gender, status } = doc.data();

        if (!status)
            return;

        if (gender === 'Male') {
            male += 1;
        } else if (gender === 'Female') {
            female += 1;
        } else {
            others += 1;
        }
    });

    return [
        { name: 'Male', value: male },
        { name: 'Female', value: female },
        { name: 'Others', value: others }
    ];
};

/**
 * Returns the age data array, excluding the member with doc ID 'testUser'.
 * @param {Object} gymData - Contains the gym details
 * @returns {Array} Array of age distribution in specified ranges
 */
export const getAgeData = async (gymData) => {
    let ageGroup1 = 0; // 0-18
    let ageGroup2 = 0; // 18-28
    let ageGroup3 = 0; // 28-45
    let ageGroup4 = 0; // 45-60
    let ageGroup5 = 0; // 60+

    const ageRef = collection(db, cityList, gymData.city, gymList, gymData.gymName, userList);
    const snapshot = await getDocs(ageRef);

    snapshot.forEach(doc => {
        // Skip the member with doc ID 'testUser'
        if (doc.id === 'testUser') {
            return;
        }

        const { age, status } = doc.data();

        if (!status)
            return;

        if (age >= 0 && age <= 18) {
            ageGroup1 += 1;
        } else if (age <= 28) {
            ageGroup2 += 1;
        } else if (age <= 45) {
            ageGroup3 += 1;
        } else if (age <= 60) {
            ageGroup4 += 1;
        } else if (age > 60) {
            ageGroup5 += 1;
        }

    });

    return [
        { name: '0-18', value: ageGroup1 },
        { name: '19-28', value: ageGroup2 },
        { name: '29-45', value: ageGroup3 },
        { name: '46-60', value: ageGroup4 },
        { name: '60+', value: ageGroup5 }
    ];
};

/**
 * Gets the list of gym's members along with their membership status.
 *
 * @param   {Object} gymData  - An object containing:
 *                             - city (String): The city where the gym is located
 *                             - gymName (String): The name of the gym
 * @returns {Array}           - A list of user objects where each includes:
 *                             - activeStatus (Boolean): True if the member is active, otherwise false
 */
export const getMemberData = async (gymData) => {
    try {
        // Create a reference to the gym's user collection
        const gymRef = collection(db, cityList, gymData.city, gymList, gymData.gymName, userList);

        // Execute the query and get the snapshot of documents
        const querySnapshot = await getDocs(gymRef);

        // Initialize an array to hold the user data
        const users = [];

        // Process each user document
        for (const doc of querySnapshot.docs) {
            if (doc.id !== "testUser") {
                const userData = doc.data();

                /** 
                 * EXTRA BLOCK OF CODE
                 * FOR COMPARING RECEIPTS
                 * AND FETCHING MEMBER STATUS
                 */ 
                // // Determine the userID and idType for fetchMemberStatus
                // let userID = "";
                // let idType = "";

                // if (userData.userID) {
                //     userID = userData.userID;
                //     idType = "userID";
                // } else if (userData.phoneNumber) {
                //     userID = userData.phoneNumber;
                //     idType = "phoneNumber";
                // } else if (userData.email) {
                //     userID = userData.email;
                //     idType = "email";
                // }

                // else {
                //     users.push({
                //         ...userData,
                //         activeStatus : false,
                //     });
                //     continue;
                // }

                // // Fetch membership status and attach it to the user object
                // const activeStatus = await fetchMemberStatus(gymData, userID, idType);

                // Push the updated user data to the list
                users.push({
                    ...userData,
                    activeStatus : parseDate(userData.membershipFrom) <= parseDate(getDate()) && parseDate(userData.membershipTill) > parseDate(getDate())
                });
            }
        }

        // Return the list of users with their active status
        return users;

    } catch (error) {
        console.error("Error getting gym members:", error);
        return [];
    }
};

/**
 * Returns an array of date and totalUsersAttendance on that date
 * This is from the 1st of the current month to the current date
 * @param {Object} gymData - Contains gym information
 * @returns {Array} attendanceList - Array of objects containing date and totalUsersAttendance
 */
export const getTotalMonthsAttendance = async (gymData) => {
    const today = new Date(); // Get today's date
    const month = today.getMonth() + 1; // getMonth() returns 0 for January, so we add 1
    const year = today.getFullYear();
    const currentMonth = month < 10 ? `0${month}-${year}` : `${month}-${year}`; // Ensure month is in "MM-YYYY" format

    const attendanceList = [];
    
    for (let day = 1; day <= today.getDate(); day++) {
        const formattedDay = day < 10 ? `0${day}` : `${day}`; // Ensure day is in "dd" format
        const dateKey = `${formattedDay}-${currentMonth}`;
        
        // Access the document for each specific date
        const dateDocRef = doc(db, cityList, gymData.city, gymList, gymData.gymName, 'Calendar', dateKey);
        const docSnapshot = await getDoc(dateDocRef);
        
        if (docSnapshot.exists()) {
            const totalUsers = docSnapshot.data().totalUsersAttendance || 0;
            attendanceList.push({ date: dateKey, totalUsers });
        } else {
            attendanceList.push({ date: dateKey, totalUsers: 0 }); // If no document exists for that date, set attendance to 0
        }
    }

    return attendanceList;
};

/**
 * THIS IS A TEMP FUNCTION
 * SORT THIS OUT BY ADDING A YYYY-MM-DD DATE FORMAT IN THE DATE AS WELL
 * 
 * 
 * Returns an array of date and totalUsersAttendance on that date.
 * This is from the 1st of the current month to the current date.
 * @param {Object} gymData - Contains gym information.
 * @returns {Array} attendanceList - Array of objects containing date and totalUsersAttendance.
 */
export const getTotalMonthsAttendance2 = async (gymData) => {
    const today = new Date();
    const month = today.getMonth() + 1;
    const year = today.getFullYear();
    const currentMonth = month < 10 ? `0${month}-${year}` : `${month}-${year}`;

    const attendanceList = [];

    // Query to get all attendance documents for the current month
    const attendanceCollection = collection(db, cityList, gymData.city, gymList, gymData.gymName, 'Calendar');
    const attendanceQuery = query(
        attendanceCollection,
        where("__name__", ">=", `01-${currentMonth}`),
        where("__name__", "<=", `${today.getDate()}-${currentMonth}`)
    );

    const querySnapshot = await getDocs(attendanceQuery);

    querySnapshot.forEach(docSnapshot => {
        const date = docSnapshot.id; // Assuming the document ID is the date in "dd-MM-YYYY" format
        const totalUsers = docSnapshot.data().totalUsersAttendance || 0;
        attendanceList.push({ date, totalUsers });
    });
    return attendanceList;
};

/**
 * This function checks if the membership status of a member is active or not
 * 
 * @param   {Object}  gymData     -   Has attributes : gymName and city
 * @param   {String}  userID      -   Identification of the user
 * @param   {String}  idType      -   Type Od Identification - userID / email / phoneNumber
 * 
 * @returns {Boolean}             -   True; if member is active, false; otherwise
 */
export const fetchMemberStatus = async(gymData, userID, idType) => {
    try {

        const today = new Date(); // Today's date as a JavaScript Date object

        const gymRef = collection(
            db,
            cityList,
            gymData.city,
            gymList,
            gymData.gymName,
            financeList,
            getCurrentYear(),
            revenueList
        );

        // Fetch all receipts with the userID
        const gymQuery = query(
            gymRef,
            where(idType, "==", userID) // Query by userID, phoneNumber, or email
        );

        // Execute the query and process results
        const querySnapshot = await getDocs(gymQuery);
        
        querySnapshot.forEach((docSnapshot) => {
            const receiptInfo = docSnapshot.data();

            if (!receiptInfo.startDate || !receiptInfo.endDate)
                return false;

            // Parse startDate and endDate from strings to Date objects
            const startDate = parseDate(receiptInfo.startDate); // Parse 'DD-MM-YYYY' to Date
            const endDate = parseDate(receiptInfo.endDate);     // Parse 'DD-MM-YYYY' to Date

            // Check if the membership is active
            if (startDate <= today && endDate > today) {
                return true;
            }
        });
    } catch (error) {
        console.error('Error in getting member status:', error);
    }

    return false; // Return true if any membership is active
}