import {
  addDoc,
  collection,
  doc,
  getDoc,
  getDocs,
  updateDoc,
  where,
  query,
} from "@firebase/firestore";
import { db } from "../config/firebase";
import { addMonthsToDate } from "./userDatabaseHelper";
import { generateRandomStringId, getCurrentYear, getDate, parseDate } from "./basicHelper";
import {
    cityList,
    financeList,
    gymList,
    revenueList,
    userList,
    gymAlerts,
} from "../components/Databases";

/**
 * Stores data in gym's database
 * @param {Object} myData - User data including city, gymName, months, userID, contactNo
 * @param {Object} response - Response object from Razorpay containing paymentID, orderID, signature
 * @returns {boolean} - True if save successful, false otherwise
 */
export const storeGymData = async (myData, response) => {
    // Initializing startDate and endDate
    let startDate = myData.startDate || getDate();
    let endDate = '';

    try {
        const gymRef = collection(db, cityList, myData.city, gymList);
        const gymDocRef = doc(gymRef, myData.gymName);
        let gymDoc = await getDoc(gymDocRef);

        // If gym doesn't exist
        if (!gymDoc.exists()) {
            const gymName = myData.gymName.toLowerCase().replace(/\s+/g, "");
            const gymQuery = query(gymRef, where("gymName", "==", gymName));
            const querySnapshot = await getDocs(gymQuery);

            if (!querySnapshot.empty) {
                gymDoc = querySnapshot.docs[0];
            } else {
                return false;
            }
        }

        const userRef = collection(
            db,
            cityList,
            myData.city,
            gymList,
            gymDoc.id,
            userList
        );
        const userQuery = query(userRef, where("userID", "==", myData.user.uid));
        const userSnapshot = await getDocs(userQuery);

        if (!userSnapshot.empty) {
            // If user is renewing
            const userDoc = userSnapshot.docs[0];
            const userData = userDoc.data();
            const membershipTillDate = parseDate(userData.membershipTill);

            // Check if the membership is still active
            if (parseDate(startDate) > membershipTillDate) {
                // Membership expired, start from today
                endDate = addMonthsToDate(startDate, myData.months);
            } else {
                // Membership still active, add the new months to the existing end date
                endDate = addMonthsToDate(userData.membershipTill, myData.months);
            }

            await updateDoc(userDoc.ref, {
                status              : true,
                membershipTill      : endDate,
                personalTrainer     : myData.hasTrainer ? myData.trainerName : false, // If hasTrainer is True, store their name, else false
                // Add a gym membership ID if necessary
            });

            const alertRef = collection(
                db,
                cityList,
                myData.city,
                gymList,
                myData.gymName,
                gymAlerts
            );

            // Add an alert
            await addDoc(alertRef, {
                timestamp: new Date().toISOString(),
                message: `${myData.userName} has renewed their membership.`,
                alertType: "gymMembership",
                userEmail: myData.email,
            });

        } else {
            // If user is new to the gym
            const userDBRef = doc(collection(db, "user"), myData.user.uid);
            const userDBDoc = await getDoc(userDBRef);
            const userDBData = userDBDoc.data();

            endDate = addMonthsToDate(startDate, myData.months);

            await addDoc(userRef, {
                gender          : userDBData.gender || myData.gender,
                age             : userDBData.age || myData.age,
                phoneNumber     : myData.user.phoneNumber || myData.phoneNumber,
                personalTrainer : myData.hasTrainer ? myData.trainerName : false, // If hasTrainer is True, store their name, else false
                profilePic      : userDBData.profilePic || "",
                email           : myData.user.email,
                userID          : myData.user.uid,
                userName        : userDBData.name,
                membershipFrom  : startDate,
                memberSince     : startDate,
                membershipTill  : endDate,
                status          : true,
                DOB             : myData.dateOfBirth || "",
                // Add a gym membership ID if necessary
            });
        }

        // Add the receipts to another collection
        const revenueRef = collection(
            db,
            cityList,
            myData.city,
            gymList,
            gymDoc.id,
            financeList,
            getCurrentYear(),
            revenueList
        );
        storeReceiptinDatabase(revenueRef, {
            ...myData,
            startDate   : startDate,
            endDate     : endDate,
        }, response.razorpay_payment_id);

        return true;
    } catch (error) {
        console.log("Error:", error);
        return false;
    }
};


/**
 * Stores the "Unknown" User in Gym's Database
 * This is the manual addition from the Gym's Dashboard
 * 
 * @param {Object} myData - Stores all the form and gym details
 * @returns {Boolean} - if storing is successful; false otherwise
 */


export const storeUnknownUserInGym = async (myData) => {
    console.log('Yes This function!');
    let startDate = myData.startDate || getDate();
    let endDate = '';

    try {
        const gymRef = collection(
            db,
            cityList,
            myData.city,
            gymList,
            myData.gymName,
            userList
        );
    const gymQuery = query(gymRef, where("email", "==", myData.email));
    const querySnapshot = await getDocs(gymQuery);

    // Check if User is "Kanjar", still not making an account
    // But always buying a membership through the gym
    if (!querySnapshot.empty) {
        // "Kanjar" member found
        const gymDoc = querySnapshot.docs[0];
        const gymData = gymDoc.data();
        myData.phoneNumber  = gymData.phoneNumber;
        myData.gender       = gymData.gender;
        const membershipTillDate = parseDate(gymData.membershipTill);

        // Check if the membership is still active
        if (parseDate(startDate) < membershipTillDate) {
            // If start Date is less than the already ending membership Date
            // Update startDate
            startDate = gymData.membershipTill
        }
        // Calculate end date
        endDate = addMonthsToDate(startDate, myData.months);

        // Only update the gymDoc
        await updateDoc(gymDoc.ref, {
            status          : true,
            membershipTill  : endDate,
            membershipFrom  : startDate,
            personalTrainer : myData.hasTrainer ? myData.trainerName : false, // If hasTrainer is True, store their name, else false
            testField       : false,
        });
    } else {
        // New member
        console.log("User Does Not Exist in Gym Database");
        endDate = addMonthsToDate(startDate, myData.months);
        try {
            await addDoc(gymRef, {
                age             : myData.age,
                gender          : myData.gender,
                userName        : myData.userName,
                email           : myData.email,
                status          : true,
                memberSince     : startDate,
                membershipFrom  : startDate,
                membershipTill  : endDate,
                months          : myData.months,
                phoneNumber     : myData.phoneNumber || "",
                personalTrainer : myData.hasTrainer ? myData.trainerName : false, // If hasTrainer is True, store their name, else false
        });
        } catch (error) {
              console.log("Error:", error);
        }
    }

    // Save Receipt
    const receiptRef = collection(
        db,
        cityList,
        myData.city,
        gymList,
        myData.gymName,
        financeList,
        getCurrentYear(),
        revenueList
    );
    // Storing Receipt in the database
    storeReceiptinDatabase(receiptRef, {
        ...myData,
        startDate   : startDate,
        endDate     : endDate,
    }, "Self");

    // Add Alert to the Gym
    const alertRef = collection(
        db,
        cityList,
        myData.city,
        gymList,
        myData.gymName,
        gymAlerts
    );
    await addDoc(alertRef, {
        timestamp   : new Date().toISOString(),
        message     : `${myData.userName} has joined the gym.`,
        alertType   : "gymMembership",
        userEmail   : myData.email,
    });

    return true;
  } catch (error) {
        console.log("Error:", error);
        return false;
  }
};

/**
 * Function used to store receits
 * @param {Ref} databaseRef   - The Reference of the database
 * @param {Object} myData     - The Data to be inserted in the database
 * @param {String} paymentID  - The paymentID of the receipt
 */
const storeReceiptinDatabase = async (databaseRef, myData, paymentID) => {
      try {
        await addDoc ( databaseRef, {
          timestamp       : new Date().toISOString(),
          paymentID       : paymentID,
          orderType       : "Gym Membership",
          months          : myData.months,
          userID          : myData.user.uid || myData.userID || "",
          amount          : myData.amount,
          userName        : myData.user.displayName || myData.userName,
          email           : myData.user.email || myData.email || "",
          personalTrainer : myData.hasTrainer ? myData.trainerName : false, // If hasTrainer is True, store their name, else false
          phoneNumber     : myData.phoneNumber,
          startDate       : myData.startDate || getDate(),
          endDate         : myData.endDate,
          gymContactNo    : myData.contactNo || '',
          receiptNumber   : generateRandomStringId(),
        })
      } catch (error) {
        console.error('Error in storing Receipt : ', error);
      }
};